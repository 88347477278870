import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils/response';
import { api, error as _error, request as _request, telco } from 'lib/types/go';
import { Params } from 'lib/utils/http';

import { NAMESPACE, RESOURCE } from './constants';

function getParams(accessToken: string, opts: api.FiberEndNodeOptions): string {
  const params = new Params();
  params.set('access_token', accessToken);
  if (opts.date !== undefined) {
    params.set('date', opts.date); 
  }
  if (opts.filter !== undefined) {
    params.set('filter', JSON.stringify(opts.filter)); 
  }
  if (opts.version !== undefined) {
    params.set('version', opts.version); 
  }
  return params.toString();
}

const error = (err: AxiosError) => catchError<_error.ObjectError>(err);
const success = (response: AxiosResponse) => setSuccess<any>(response);

export function endNode(baseURL: string, accessToken: string) {
  function makeRequest(opts: api.FiberEndNodeOptions): _request.Request<telco.fiber.EndNode, _error.ObjectError> {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const params = getParams(accessToken, opts);
    const url = `${baseURL}/${NAMESPACE}/${RESOURCE}/${opts.id}/end_node?${params}`;
    const request = axios.get(url, { cancelToken }).then(success).catch(error);
    return { cancel, request };
  }
  return makeRequest;
}
