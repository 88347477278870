import axios, { AxiosError, AxiosResponse } from 'axios';
import { api, error as _error, request as _request } from 'lib/types/go';
import { Details } from 'lib/types/go/options';
import { Params } from 'lib/utils/http';

import { catchError, setSuccess } from './response';

function getParams(accessToken: string, opts: api.ParentStructureOptions<Details>): string {
  const params = new Params();
  params.set('access_token', accessToken);
  params.set('details', opts.details === undefined ? 'full' : opts.details);
  if (opts.date !== undefined) {
    params.set('date', opts.date); 
  }
  if (opts.depth !== undefined) {
    params.set('depth', opts.depth.toString()); 
  }
  if (opts.output_geoms !== undefined) {
    params.set('output_geoms', opts.output_geoms.toString()); 
  }
  if (opts.version !== undefined) {
    params.set('version', opts.version); 
  }
  return params.toString();
}

export function parentStructure<IDOnly, Full, Debug>(namespace: 'dk' | 'telco', resource: string) {
  const error = (err: AxiosError) => catchError<_error.ObjectError>(err);
  const success = (response: AxiosResponse) => setSuccess<any>(response);
  return (baseURL: string, accessToken: string) => {
    function makeRequest(opts: api.ParentStructureOptions<'id_only'>): _request.Request<IDOnly, _error.ObjectError>;
    function makeRequest(opts: api.ParentStructureOptions<'full'>): _request.Request<Full, _error.ObjectError>;
    function makeRequest(opts: api.ParentStructureOptions<'debug'>): _request.Request<Debug, _error.ObjectError>;
    function makeRequest(opts: api.ParentStructureOptions<Details>) {
      const source = axios.CancelToken.source();
      const cancelToken = source.token;
      const cancel = source.cancel;
      const params = getParams(accessToken, opts);
      const url = `${baseURL}/${namespace}/${resource}/${opts.id}/parent_structure?${params}`;
      const request = axios.get(url, { cancelToken }).then(success).catch(error);
      return { cancel, request };
    }
    return makeRequest;
  };
}
