import AccessTime from '@material-ui/icons/AccessTime';
import Album from '@material-ui/icons/Album';
import AspectRatio from '@material-ui/icons/AspectRatio';
import BlurCircular from '@material-ui/icons/BlurCircular';
import BorderOuter from '@material-ui/icons/BorderOuter';
import CallSplit from '@material-ui/icons/CallSplit';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CropFree from '@material-ui/icons/CropFree';
import Dehaze from '@material-ui/icons/Dehaze';
import Description from '@material-ui/icons/Description';
import DeveloperBoard from '@material-ui/icons/DeveloperBoard';
import DeviceHub from '@material-ui/icons/DeviceHub';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FiberSmartRecord from '@material-ui/icons/FiberSmartRecord';
import FolderOpen from '@material-ui/icons/FolderOpen';
import Gesture from '@material-ui/icons/Gesture';
import GroupWork from '@material-ui/icons/GroupWork';
import Home from '@material-ui/icons/Home';
import Landscape from '@material-ui/icons/Landscape';
import LinearScale from '@material-ui/icons/LinearScale';
import ListAlt from '@material-ui/icons/ListAlt';
import LocationCity from '@material-ui/icons/LocationCity';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/Person';
import PersonOutline from '@material-ui/icons/PersonOutline';
import PowerInput from '@material-ui/icons/PowerInput';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import Room from '@material-ui/icons/Room';
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import Storage from '@material-ui/icons/Storage';
import Timeline from '@material-ui/icons/Timeline';
import ViewArray from '@material-ui/icons/ViewArray';
import ViewComfy from '@material-ui/icons/ViewComfy';
import ViewModule from '@material-ui/icons/ViewModule';
import ViewStream from '@material-ui/icons/ViewStream';
import { go } from 'gridtools/types';
import WorkOutline from "@material-ui/icons/WorkOutline";

export const GoDocumentIcon = Description;
export const GoWorkOrderIcon = WorkOutline;
export const TelcoContainerUndergroundUtilityBoxIcon = Album;
export const TelcoFiberBundleIcon = BlurCircular;
export const TelcoOpticalSpliceIcon = CallSplit;
export const TelcoFiberPortIcon = CheckBoxOutlineBlank;
export const RelationsIcon = BorderOuter;
export const TelcoOpticalPathIcon = Dehaze;
export const TelcoSplitterIcon = DeviceHub;
export const DKRoadIcon = DirectionsCar;
export const DocumentsIcon = FolderOpen;
export const VisualizationsIcon = DeveloperBoard;
export const TelcoFiberIcon = FiberManualRecord;
export const TelcoFiberNodeIcon = FiberSmartRecord;
export const TelcoRouteIcon = Gesture;
export const TelcoConduitIcon = GroupWork;
export const TelcoZoneIcon = Landscape;
export const TelcoConduitAdapterIcon = LinearScale;
export const PropertiesIcon = ListAlt;
export const DKMunicipalityIcon = LocationCity;
export const TelcoCustomerIcon = People;
export const DKAccessAddressIcon = Person;
export const DKSpecificAddressIcon = PersonOutline;
export const TelcoSpliceTrayIcon = PowerInput;
export const TelcoFiberCableIcon = RadioButtonChecked;
export const TelcoRackEquipmentIcon = Storage;
export const FiberTracingIcon = Timeline;
export const TelcoContainerStreetCabinetIcon = ViewArray;
export const TelcoFigureEightIcon = ViewComfy;
export const TelcoContainerIcon = ViewModule;
export const TelcoContainerHubIcon = ViewStream;
export const TelcoBuilding = Home;
export const TelcoBuildingFloor = AspectRatio;
export const TelcoBuildingUnit = CropFree;
export const TelcoLogicalLocation = Room;
export const SketchIcon = ScatterPlot;
export const HistoryIcon = AccessTime;
export const TEXT_SEARCH_SCOPE: go.entity.Entity[] = [
  'dk_access_address',
  'dk_municipality',
  'dk_road',
  'dk_specific_address',
  'telco_building' as any,
  'telco_conduit',
  'telco_conduit_adapter',
  'telco_container',
  'telco_customer',
  'telco_fiber_cable',
  'telco_fiber_port',
  'telco_figure_eight',
  'telco_logical_location' as any,
  'telco_optical_path',
  'telco_optical_splice',
  'telco_rack_equipment',
  'telco_route',
  'telco_splice_tray',
  'telco_splitter',
  'telco_zone',
  'telco_continer_street_cabinet'
];

export const APP_WINDOW_NAME = 'go-maps-app';
