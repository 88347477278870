import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { listEquals } from 'gridtools/utils/equals';
import { stringComparer } from 'gridtools/utils/tests';
import * as React from 'react';
import * as intl from 'react-intl-universal';

import { _Props, _State } from './types';

export class Loading extends React.Component<_Props, _State> {
  constructor(props: _Props) {
    super(props);
    this.state = {
      error: intl.get('errors.loading_failed'),
    };
  }

  hasError(props: _Props) {
    const { error, errorMsg, timedOut } = props;
    return error || timedOut || errorMsg !== undefined;
  }

  isLoading(props: _Props) {
    const { isLoading, pastDelay } = props;
    return !this.hasError(props) && isLoading && pastDelay;
  }

  shouldComponentUpdate(nextProps: _Props) {
    const prevProps = this.props;
    // don't rerender if nothing has changed
    const prevKeys = Object.keys(prevProps) as Array<keyof _Props>;
    const nextKeys = Object.keys(nextProps) as Array<keyof _Props>;
    const sortedPrevKeys = prevKeys.sort(stringComparer);
    const sortedNextKeys = nextKeys.sort(stringComparer);
    const keysEquals = listEquals(sortedPrevKeys, sortedNextKeys);
    if (keysEquals && nextKeys.every((key) => prevProps[key] === nextProps[key])) {
      return false;
    }
    // do not rerender if component was previously loading and still is
    // this ensures that the loading animation won't restart
    const prevIsLoading = this.isLoading(prevProps);
    const nextIsLoading = this.isLoading(nextProps);
    return !(prevIsLoading && nextIsLoading);
  }

  render() {
    const { classes, errorMsg, size } = this.props;
    if (this.hasError(this.props)) {
      return (
        <div className={classes.root}>
          <Typography color='error' variant='body1'>{this.state.error}</Typography>
          {errorMsg === undefined ? null : <Typography color='default' variant='body1'>{errorMsg}</Typography>}
        </div>
      );
    } if (this.isLoading(this.props)) {
      return (
        <div className={classes.root}>
          <CircularProgress color='primary' size={size} />
        </div>
      );
    }
    return null;
  }
}
