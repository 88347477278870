import { Login as LoginWrapper, LoginChangedFields } from 'gridtools/ui';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import * as intl from 'react-intl-universal';
import { Typography } from '@material-ui/core';

import { _Props, _State } from './types';

export class Login extends React.Component<_Props, _State> {
  static getDerivedStateFromProps(nextProps: _Props, prevState: _State): null | Partial<_State> {
    if (nextProps.response !== prevState.prevResponse) {
      const { response } = nextProps;
      const nextState: Partial<_State> = { prevResponse: response };
      nextState.form = response === null || response.type === 'cancelled' || response.type === 'success'
        ? { ...prevState.form, error: undefined }
        : { ...prevState.form, error: response.error.message };
      return nextState;
    }
    return null;
  }

  private _loadingTimeout: any;

  constructor(props: _Props) {
    super(props);
    this.state = {
      form: {
        fields: {
          password: {
            label: intl.get('login.password'),
            value: '',
          },
          remember: {
            label: intl.get('login.remember'),
            value: false,
          },
          username: {
            label: intl.get('login.username'),
            value: '',
          },
        },
        onChange: this.onChange,
        onSubmit: this.onSubmit,
      },
      loading: false,
      logo: require('assets/go-logo.png'),
      prevResponse: props.response,
      text: {
        submit: intl.get('login.submit'),
        title: intl.get('login.title'),
      },
      version: intl.get('appBar.about.version', { version: VERSION }),
    };
  }

  componentDidMount() {
    this.props.onLoad();
    this.props.analytics.pageView(() => {
      const page = '/login';
      const title = 'Login';
      return { page, title };
    });
  }

  componentDidUpdate(prevProps: _Props) {
    if (this.props.response !== prevProps.response && this.props.response !== null) {
      this.clearLoadingTimeout();
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.clearLoadingTimeout();
  }

  clearLoadingTimeout() {
    if (this._loadingTimeout !== undefined) {
      clearTimeout(this._loadingTimeout);
    }
  }

  onChange = (fields: LoginChangedFields) => {
    const keys = Object.keys(fields) as Array<keyof LoginChangedFields>;
    keys.forEach((key) => {
      const field = fields[key];
      if (field !== undefined) {
        this.setState((prevState) => ({
          form: {
            ...prevState.form,
            error: undefined,
            fields: {
              ...prevState.form.fields,
              [key]: {
                ...prevState.form.fields[key],
                value: field,
              },
            },
          },
        }));
      }
    });
  };

  onSubmit = () => {
    const fields = this.state.form.fields;
    const password = fields.password.value;
    const remember = fields.remember.value;
    const username = fields.username.value;
    this.props.onLogin(username, password, remember);
    this.clearLoadingTimeout();
    this._loadingTimeout = setTimeout(() => this.setState({ loading: true }), 250);
  };

  render() {
    const { form, loading, logo, text, version } = this.state;
    return (
      <DocumentTitle title='Grid Optimizer'>
        <>
          <LoginWrapper form={form} loading={loading} logo={logo} text={text} />
          <footer className={this.props.classes.about}>
            <Typography variant='subtitle1'>{version}</Typography>
          </footer>
        </>
      </DocumentTitle>
    );
  }
}
