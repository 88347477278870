// name, value
type Param = [string, string];

export class Params {
  private _params: Param[] = [];

  get(key: string): string | null {
    const result = this._params.find((pair) => pair[0] === key);
    return result === undefined ? null : result[1];
  }

  set(key: string, value: string) {
    const eKey = this._encode(key);
    const eValue = this._encode(value);
    const eParam: Param = [eKey, eValue];
    const index = this._params.findIndex((pair) => pair[0] === eKey);
    if (index === -1) {
      this._params.push(eParam);
    } else {
      this._params[index] = eParam;
    }
  }

  toString() {
    return this._params.map((pair) => pair[0] + '=' + pair[1]).join('&');
  }

  private _encode(v: string): string {
    return encodeURIComponent(v);
  }
}
