import { fail, removeResults, setPolygon, setResults, startLoading } from 'app/reducers/map/search';
import { objectsWithinPolygon } from 'gridtools/go/telco';
import { geojson, go, utils } from 'gridtools/types';
import * as intl from 'react-intl-universal';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { MapLayersCustomState, MapLayersGO, MapLayersGOState } from 'types';
import { StoreState } from 'types/store';

import { fetchLayersSaga } from './map-info';

const SEARCH = 'sagas/map/search';
const FETCH_LAYERS = 'sagas/map/fetch-layers';

type SearchCallback = (result: go.telco.objects_within_polygon.ObjectsWithinPolygon) => void;
type SearchResponse = utils.UnwrapPromise<ReturnType<ReturnType<typeof objectsWithinPolygon>>['request']>;

export type SearchAction = {
  callback?: SearchCallback;
  polygon: geojson.Polygon;
  type: typeof SEARCH;
};

export type FetchLayersAction = {
  type: typeof FETCH_LAYERS;
};

export type MapAction =
  | SearchAction
  | FetchLayersAction;

export function search(polygon: geojson.Polygon, callback?: SearchCallback): SearchAction {
  return { callback, polygon, type: SEARCH };
}

export function fetchLayersInfo() {
  return { type: FETCH_LAYERS as typeof FETCH_LAYERS };
}

function getGOLayers(state: StoreState) {
  return state.map.layers.go;
}

function getCustomLayers(state: StoreState) {
  return state.map.layers.custom;
}

const cl = FEATURES.map.custom_layers;
const _customLayers = cl === undefined ? [] : cl;

function layerIsInScope(goLayers: MapLayersGOState, customLayers: MapLayersCustomState) {
  // a GO layer is in scope if it is selected in the GO layers menu
  // or if one (or more) of the selected custom layers specify it to be in scope 
  return function(layerKey: MapLayersGO): boolean {
    return goLayers[layerKey] || (
      _customLayers.some((customLayer) => (
        customLayers[customLayer.key]
        && customLayer.go_objects !== undefined
        && customLayer.go_objects.some((goObject) => goObject === layerKey)
      ))
    );
  };
}

const goLayer2entity: {[key in MapLayersGO]: go.entity.Entity} = {
  access_address: 'dk_access_address',
  conduit: 'telco_conduit',
  conduit_adapter: 'telco_conduit_adapter',
  container_hub: 'telco_container',
  container_street_cabinet: 'telco_container',
  container_underground_utility_box: 'telco_container',
  customer: 'telco_customer',
  fiber_cable: 'telco_fiber_cable',
  figure_eight: 'telco_figure_eight',
  optical_path: 'telco_optical_path',
  optical_splice: 'telco_optical_splice',
  route: 'telco_route',
  workorders: 'go_workorder',
  zone: 'telco_zone',
  building: 'telco_building' as any,
  logical_location: 'telco_logical_location' as any,
  sketch: 'go_sketch' as any,
};

function getScope(goLayers: MapLayersGOState, customLayers: MapLayersCustomState) {
  const goLayersKeys = Object.keys(goLayers) as MapLayersGO[];
  const list = goLayersKeys.filter(layerIsInScope(goLayers, customLayers)).map((layerKey) => goLayer2entity[layerKey]);
  const set = new Set(list);
  const searchScope: go.entity.Entity[] = [];
  set.forEach((value) => searchScope.push(value));
  return searchScope;
}

function* searchSaga(action: SearchAction) {
  const token = yield select((state: StoreState) => state.auth.user === null ? null : state.auth.user.token);
  if (token !== null) {
    yield put(setPolygon(action.polygon));
    yield put(removeResults());
    const goLayers: MapLayersGOState = yield select(getGOLayers);
    const customLayers: MapLayersCustomState = yield select(getCustomLayers);
    const scope = getScope(goLayers, customLayers);
    // only do a search if any layer is in scope
    if (scope.length !== 0) {
      const boundSearch = objectsWithinPolygon(API_URLS.gridoptimizer, token);
      const opts: go.telco.objects_within_polygon.ObjectsWithinPolygonOptions = {
        details: 'full',
        output_geoms: true,
        polygon: action.polygon,
        scope,
      };
      try {
        yield put(startLoading());
        const getResponse = () => boundSearch(opts).request;
        const response: SearchResponse = yield call(getResponse);
        if (response.type === 'success') {
          yield put(setResults(response.result));
          if (action.callback !== undefined) {
            action.callback(response.result);
          }
        } else if (response.type === 'error') {
          yield put(fail(response.error));
        }
      } catch {
        const message = intl.get('errors.connection.title');
        const name = intl.get('errors.connection.message');
        yield put(fail({ message, name, status: -1 }));
      }
    }
  }
}

export default function* mapSaga() {
  yield takeLatest(SEARCH, searchSaga);
  yield takeLatest(FETCH_LAYERS, fetchLayersSaga);
}
