import {
  BBox,
  Coordinates,
  CRS,
  Feature,
  FeatureCollection,
  GeoJSON,
  GeometryCollection,
  GeometryCollectionGeometries
} from 'lib/types/geojson';
import { Projection } from 'lib/types/map/projection';
import proj from 'ol/proj';

export type Proj = Projection;

function transformBBox(bbox: BBox | undefined, from: Proj, to: Proj): BBox | undefined {
  if (bbox === undefined) {
    return undefined;
  }
  const c1 = changeCoordinateProjection([bbox[0], bbox[1]], from, to);
  const c2 = changeCoordinateProjection([bbox[2], bbox[3]], from, to);
  return [c1[0], c1[1], c2[0], c2[1]];
}

function transformCRS(crs: CRS | undefined, to: Proj): CRS | undefined {
  return crs === undefined ? undefined : { properties: { name: to }, type: 'name' };
}

function transformCoordinates(coordinates: Coordinates[], from: Proj, to: Proj): typeof coordinates {
  return coordinates.map((coordinate) => changeCoordinateProjection(coordinate, from, to));
}

function transformCoordinatesArray(array: Coordinates[][], from: Proj, to: Proj): typeof array {
  return array.map((coordinates) => transformCoordinates(coordinates, from, to));
}

function transformCoordinatesMatrix(matrix: Coordinates[][][], from: Proj, to: Proj): typeof matrix {
  return matrix.map((array) => transformCoordinatesArray(array, from, to));
}

function transformGeometry(geometry: GeometryCollectionGeometries, from: Proj, to: Proj): typeof geometry {
  let coordinates: Coordinates | Coordinates[] | Coordinates[][] | Coordinates[][][];
  const bbox = transformBBox(geometry.bbox, from, to);
  const crs = transformCRS(geometry.crs, to);
  switch (geometry.type) {
    case 'LineString':
      coordinates = transformCoordinates(geometry.coordinates, from, to);
      return { ...geometry, bbox, crs, coordinates };
    case 'MultiLineString':
      coordinates = transformCoordinatesArray(geometry.coordinates, from, to);
      return { ...geometry, bbox, crs, coordinates };
    case 'MultiPoint':
      coordinates = transformCoordinates(geometry.coordinates, from, to);
      return { ...geometry, bbox, crs, coordinates };
    case 'MultiPolygon':
      coordinates = transformCoordinatesMatrix(geometry.coordinates, from, to);
      return { ...geometry, bbox, crs, coordinates };
    case 'Point':
      coordinates = changeCoordinateProjection(geometry.coordinates, from, to);
      return { ...geometry, bbox, crs, coordinates };
    case 'Polygon':
      coordinates = transformCoordinatesArray(geometry.coordinates, from, to);
      return { ...geometry, bbox, crs, coordinates };
  }
}

function transformGeometryCollection(collection: GeometryCollection, from: Proj, to: Proj): typeof collection {
  const bbox = transformBBox(collection.bbox, from, to);
  const crs = transformCRS(collection.crs, to);
  const geometries = collection.geometries.map((geometry) => transformGeometry(geometry, from, to));
  return { ...collection, bbox, crs, geometries };
}

function transformFeature<P extends object>(feature: Feature<P>, from: Proj, to: Proj): typeof feature {
  const bbox = transformBBox(feature.bbox, from, to);
  const crs = transformCRS(feature.crs, to);
  const geometry = feature.geometry;
  if (geometry === null) {
    return { ...feature, bbox };
  }
  switch (geometry.type) {
    case 'GeometryCollection': return { ...feature, bbox, crs, geometry: transformGeometryCollection(geometry, from, to) };
    default: return { ...feature, bbox, crs, geometry: transformGeometry(geometry, from, to) };
  }
}

function transformFeatureCollection<P extends object>(collection: FeatureCollection<P>, from: Proj, to: Proj): typeof collection {
  const bbox = transformBBox(collection.bbox, from, to);
  const crs = transformCRS(collection.crs, to);
  const features = collection.features.map((feature) => transformFeature(feature, from, to));
  return { ...collection, bbox, crs, features };
}

export function changeCoordinateProjection(coordinate: Coordinates, from: Proj, to: Proj): typeof coordinate {
  return proj.transform([coordinate[0], coordinate[1]], from, to);
}

export function changeProjection<P extends object>(data: GeoJSON<P>, from: Proj, to: Proj): typeof data {
  if (from === to) {
    return data;
  }
  switch (data.type) {
    case 'LineString': return transformGeometry(data, from, to);
    case 'MultiLineString': return transformGeometry(data, from, to);
    case 'MultiPoint': return transformGeometry(data, from, to);
    case 'MultiPolygon': return transformGeometry(data, from, to);
    case 'Point': return transformGeometry(data, from, to);
    case 'Polygon': return transformGeometry(data, from, to);
    case 'GeometryCollection': return transformGeometryCollection(data, from, to);
    case 'Feature': return transformFeature(data, from, to);
    case 'FeatureCollection': return transformFeatureCollection(data, from, to);
    // this case should be impossible
    default: return data;
  }
}
