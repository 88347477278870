import { Loading } from 'app/components/Loading';
import { LoggedInApp } from 'app/components/LoggedInApp';
import { Login } from 'app/components/Login';
import { formatDate } from 'app/utils/dates';
import { loadLanguage } from 'app/utils/enums';
import { loadUser, removeUser } from 'app/utils/users';
import { App as GridToolsApp, theme } from 'gridtools/ui';
import * as React from 'react';
import * as intl from 'react-intl-universal';
import * as moment from 'moment';

import { _Props } from './types';

const LOCALES: {[key: string]: object} = {};

export class App extends React.Component<_Props> {
  componentDidMount() {
    // get ortofoto token
    this.props.getKortforsyningenToken();

    // load map layers info
    this.props.fetchMapLayersInfo();
    // load locale date
    this.loadLocales();

    // login using SAML or auto login if user has opted in to be remembered
    if (FEATURES.saml) {
      this.samlLogin();
    } else {
      // auto login
      const user = loadUser();
      if (user !== null) {
        const now = new Date();
        const startDate = new Date(user.created);
        const endDate = new Date(startDate.getTime() + user.ttl * 1000);
        const hoursLeft = (endDate.getTime() - now.getTime()) / (1000 * 60 * 60); // 1 hour or more left for autolog
        if (hoursLeft > 1) {
          this.props.login(user);
        } else {
          // TODO: reauthenticate token when API in Grid Optimizer is available
          removeUser();
        }
      }
    }
  }

  samlLogin() {
    const { history, location } = window;
    let query = location.search;
    const pattern = /([?&])SAMLResponse=([^$&]+)($|&)/;
    const match = query.match(pattern);
    if (match === null) {
      this.props.samlRequest();
    } else {
      const samlResponse = match[2];
      this.props.samlLogin(samlResponse);
      // remove SAMLResponse from query string
      query = query.replace(pattern, '');
      if (query.length !== 0 && query[0] !== '?') {
        query = '?' + query;
      }
      const cleanURI = `${location.protocol}//${location.host}${location.pathname}${query}`;
      history.replaceState({}, document.title, cleanURI);
    }
  }

  shouldComponentUpdate(nextProps: _Props) {
    if (super.shouldComponentUpdate !== undefined) {
      return super.shouldComponentUpdate(nextProps, {}, undefined);
    } 
    return true;
  }

  private patchIntl() {
    const obj = intl as any;
    if (!obj.__get) {
      obj.__get = intl.get;
      obj.get = (key: string, variables: any) => {
        if (variables && !variables.__keepValues) {
          Object.keys(variables).forEach(field => {
            const value = variables[field];
            if ((value && moment.isMoment(value)) || value instanceof Date) variables[field] = formatDate(value);
          });
        }
        return obj.__get(key, variables);
      };
    }
  }

  async loadLocales() {
    this.props.loadLocales(true);
    const locale = loadLanguage();
    this.props.analytics.setLanguage(locale);
    LOCALES[locale] = await import(`i18n/${locale}`).then((i18nModule) => i18nModule.default);
    await intl.init({ currentLocale: locale, locales: LOCALES });
    this.patchIntl();
    moment.locale(locale);
    this.props.loadLocales(false);
  }

  render() {
    const { classes, user } = this.props;
    const { locales, loggingIn, samlRequest, state: { error, timedOut } } = this.props.loading;
    const isLoading = locales || loggingIn || samlRequest || this.props.loading.state.isLoading;
    const body = locales || loggingIn || samlRequest || timedOut || (error !== null && error !== undefined) ? null
      : user === null ? <Login />
        : <LoggedInApp user={user} />;
    return (
      <div className={classes.root}>
        <Loading error={error} isLoading={isLoading} pastDelay={true} timedOut={timedOut} size={96} />
        <GridToolsApp appTheme={theme}>
          {body}
        </GridToolsApp>
      </div>
    );
  }
}
