import * as React from 'react';
import { STYLES } from './constants';

type Props = {
  headers: string[];
};

const Header: React.FC<Props> = props => {
  const { large } = STYLES.text;
  const lines = props.headers.map((h, i) => {
    const y = i === 0 ? 48
      : (52 + i * large.fontSize * 1.35);
    return <text key={i} x={15} y={y}
                 style={large}>{h}</text>;
  });

  return <>{lines}</>;
};
export default Header;
