import rootReducer from 'app/reducers';
import rootSaga from 'app/sagas';
import { getDefaultState } from 'app/store/defaults';
import * as createRavenMiddleware from 'raven-for-redux';
import * as Raven from 'raven-js';
import { applyMiddleware, compose, createStore, Reducer, Store, StoreEnhancer } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { StoreState } from 'types';

async function configureDevelopmentStore(state?: StoreState): Promise<Store<StoreState>> {
  const sagaMiddleware = createSagaMiddleware();
  const DevTools = await import('app/components/DevTools').then((devToolsModule) => /* webpackMode: "lazy" */ devToolsModule.default);

  const enhancer: StoreEnhancer = compose(
    applyMiddleware(sagaMiddleware),
    DevTools.instrument()
  );

  const rootState = getDefaultState(state);
  const store = createStore(rootReducer as Reducer<StoreState>, rootState, enhancer);
  let sagaTask = sagaMiddleware.run(rootSaga);

  if (module.hot !== undefined) {
    module.hot.accept('app/reducers', () => {
      import('app/reducers').then((reducersModule) => (
        /* webpackMode: "eager" */
        store.replaceReducer(reducersModule.default as Reducer<StoreState>)));
    });
    // https://stackoverflow.com/a/40783428/1211261
    module.hot.accept('app/sagas', () => {
      import('app/sagas').then((sagasModule) => {
        /* webpackMode: "eager" */
        const newRootSaga = sagasModule.default;
        sagaTask.cancel();
        sagaTask.toPromise().then(() => {
          sagaTask = sagaMiddleware.run(newRootSaga);
        });
      });
    });
  }

  return store;
}

async function configureProductionStore(state?: StoreState): Promise<Store<StoreState>> {
  Raven.config('https://427f5b6d13fe4b3088a6d47cd4688114@sentry.io/1205527').install();
  const sagaMiddlewareOnError = (error: Error) => {
    Raven.captureException(error); 
  };
  const sagaMiddleware = createSagaMiddleware({ onError: sagaMiddlewareOnError });
  const enhancer = applyMiddleware(sagaMiddleware, createRavenMiddleware(Raven));
  const rootState = getDefaultState(state);
  const store = createStore(rootReducer as Reducer<StoreState>, rootState, enhancer);
  sagaMiddleware.run(rootSaga);
  return store;
}

export async function configureStore(state?: StoreState): Promise<Store<StoreState>> {
  return IS_DEVELOPMENT ? configureDevelopmentStore(state) : configureProductionStore(state);
}
