import withStyles from '@material-ui/core/styles/withStyles';
import { analytics } from 'app/containers/Analytics';
import { autoLogin, samlLogin } from 'app/sagas/auth';
import { samlRequest } from 'app/sagas/saml';
import { finishLoadingLocales, startLoadingLocales } from 'app/reducers/loading';
import { getToken as getKortforsyningenToken } from 'app/sagas/kortforsyningen';
import { fetchLayersInfo } from 'app/sagas/map';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { StoreState } from 'types';

import { App } from './App';
import { _DispatchProps, _StateProps, _Styles } from './types';

const styles: _Styles = () => ({
  root: {
    height: '100vh',
    width: '100vw',
  },
});

const dispatchToProps = (dispatch: Dispatch): _DispatchProps => ({
  getKortforsyningenToken: () => {
    const action = getKortforsyningenToken();
    dispatch(action);
  },
  fetchMapLayersInfo: () => dispatch(fetchLayersInfo()),
  loadLocales: (start) => {
    if (start) {
      dispatch(startLoadingLocales());
    } else {
      dispatch(finishLoadingLocales());
    }
  },
  login: (user) => {
    const action = autoLogin(user.userId, user.id);
    dispatch(action);
  },
  samlLogin: (samlResponse) => {
    const action = samlLogin(samlResponse);
    dispatch(action);
  },
  samlRequest: () => {
    const action = samlRequest();
    dispatch(action);
  }
});

const stateToProps = (state: StoreState): _StateProps => ({
  loading: state.loading,
  user: state.auth.user,
});

const Analytics = analytics(App);
const Styled = withStyles(styles)(Analytics);
const Connected = connect(stateToProps, dispatchToProps)(Styled);
const WithRouter = withRouter(Connected);
export default WithRouter;
