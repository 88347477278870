import { StoreState } from 'types';

import { getMapLayers } from './map-layers';
import { getGOProperties } from './go-properties';

export function getDefaultState(state?: StoreState): StoreState {
  return state !== undefined ? state : {
    actions: {
      droneSelect: false,
      mapLayers: false,
      measure: false,
      print: false,
      crs: 'EPSG:4326',
      gotoMapOnOpen: true, // loadMapGoto(),
    },
    auth: {
      kortforsyningen: null,
      loginResponse: null,
      user: null,
    },
    drone: {
      analysis: [],
      selection: null,
    },
    edit: {
      editorKey: '',
    },
    go: {
      properties: getGOProperties(),
    },
    loading: {
      locales: true,
      loggingIn: false,
      samlRequest: false,
      state: {
        error: null,
        isLoading: true,
        pastDelay: false,
        timedOut: false,
      },
    },
    map: {
      draw: {
        color: 'red',
        state: 'not-started',
        documentGeometry: null,
        tool: null,
        selected: null,
        selectionEnabled: false,
        currentTextEdit: null
      },
      layers: getMapLayers(),
      location: {
        position: null,
        visible: false,
      },
      measurement: [],
      search: {
        polygon: null,
        lastClickLocation: null,
        state: null,
      },
      selected: null,
      trace: null,
    },
    messages: [],
    print: {
      format: null,
      loading: false,
      orientation: 'landscape',
    },
    search: null,
    visualization: null,
    workorders: {
      listOpened: false,
    },
  };
}
