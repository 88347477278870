import { go } from 'gridtools/types';
import { BaseDetails } from 'gridtools/types/go/telco/output';
import { GOProperties } from 'types';

export function getColor(type: keyof GOProperties): string {
  switch (type) {
    case 'dk_access_address': return '#dd5a22';
    case 'dk_municipality': return '#ddd022';
    case 'dk_road': return '#74dd22';
    case 'dk_specific_address': return '#22dd83';
    case 'go_document': return '#fff';
    case 'go_workorder': return '#fff';
    case 'telco_building': return '#aaa';
    case 'telco_building_floor': return '#fff';
    case 'telco_building_unit': return '#fff';
    case 'telco_conduit': return '#eeaa00';
    case 'telco_conduit_adapter': return '#eeaa00';
    case 'telco_container': return '#1652ec';
    case 'telco_customer': return '#ab0b10';
    case 'telco_fiber': return '#1c929e';
    case 'telco_fiber_bundle': return '#1c6260';
    case 'telco_fiber_cable': return '#2b39b1';
    case 'telco_fiber_port': return '#a722dd';
    case 'telco_figure_eight': return '#09a8be';
    case 'telco_logical_location': return '#f00';
    case 'telco_optical_path': return '#760eaf';
    case 'telco_optical_splice': return '#8827a4';
    case 'telco_rack_equipment': return '#dd229d';
    case 'telco_route': return '#74eb74';
    case 'telco_splice_tray': return '#dd2249';
    case 'telco_splitter': return '#dd2222';
    case 'telco_zone': return '#055573';
  }
}

export function getFiberColor(fiber: go.telco.fiber.Fiber) {
  return getColorByName(fiber.details.color);
}
// TODO: update this once fiber colors have become properly enumerated
export function getColorByName(color: string | null) {
  switch (color) {
    case 'Aqua w/ring':
    case 'Aqua': return '#00FFFF';
    case 'Black/Black':
    case 'Black w/ring':
    case 'Black': return '#000000';
    case 'Blue/Black':
    case 'Blue w/ring':
    case 'Blue': return '#0000FF';
    case 'Brown/Black':
    case 'Brown w/ring':
    case 'Brown': return '#A52A2A';
    case 'Green/Black':
    case 'Green w/ring':
    case 'Green': return '#008000';
    case 'Grey/Black':
    case 'Grey': return '#808080';
    case 'Orange/Black':
    case 'Orange w/ring':
    case 'Orange': return '#FFA500';
    case 'Pink/Black':
    case 'Pink': return '#FFC0CB';
    case 'Red/Black':
    case 'Red w/ring':
    case 'Red': return '#FF0000';
    case 'Rose w/ring':
    case 'Rose': return '#700000';
    case 'Slate w/ring': return '#708090';
    case 'Turquoise/Black':
    case 'Turquoise': return '#40E0D0';
    case 'Violet/Black':
    case 'Violet w/ring':
    case 'Violet': return '#EE82EE';
    case 'White/Black':
    case 'White w/ring':
    case 'White': return '#FFFFFF';
    case 'Yellow/Black':
    case 'Yellow w/ring':
    case 'Yellow': return '#FFFF00';
    default: return null;
  }
}

export function getRouteColor(route: { details: BaseDetails }) {
  switch (route.details.op_status) {
    case 'In Service': return '#008800';
    case 'Planned': return '#74eb74';
    case 'Out of service': return '#000';
    default: return '#555';
  }
}
