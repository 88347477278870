import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils';
import { error as _error, request as _request, users } from 'lib/types/go';
import { getUserCompany } from './company-data';

const error = (err: AxiosError) => catchError<_error.LoginError>(err);
const success = (response: AxiosResponse) => {
  getUserCompany(response.data.userId, response.data.id);
  return (setSuccess<users.UsersLoginResult>(response));
};

export function login(baseUrl: string) {
  return (opts: users.UsersLogin): _request.Request<users.UsersLoginResult, _error.LoginError> => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const url = `${baseUrl}/users/login`;
    const headers = { 'Content-Type': 'application/json' };
    const request = axios.post(url, opts, { cancelToken, headers }).then(success).catch(error);
    return { cancel, request };
  };
}
